<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('irri_pump_main.maintenance_task') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('globalTrans.organization')"
              label-for="org_id"
            >
              <b-form-select
                plain
                id="org_id"
                v-model="search.org_id"
                :options="orgList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('irri_pump_main.complain_id')"
              label-for="complain_id"
            >
              <b-form-input
                id="complain_id"
                v-model="search.complain_id"
                placeholder=""
                ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('pump_install.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="search.name"
                placeholder=""
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('irri_pump_main.subject')"
              label-for="subject"
            >
              <b-form-input
                id="irri_pump_main"
                v-model="search.subject"
                placeholder=""
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="text-right">
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('irri_pump_main.maintenance_task') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered :emptyText="$t('globalTrans.noDataFound')" show-empty hover :items="complains" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(complain_id)="data">
                      {{ data.item.complain_id }}
                    </template>
                    <template v-slot:cell(identified_quipment)="data">
                      <a href="javascript:" class="btn_table_action table_action_view" v-b-modal.modal-3 variant=" iq-bg-success mr-1 mb-1" size="sm" :title="$t('irri_pump_main.identified_quipment')" @click="view(data.item, 1, $t('irri_pump_main.identified_quipment'))"><i class="far fa-eye"></i></a>
                    </template>
                    <template v-slot:cell(updated_at)="data">
                      <span :title="data.item.created_at | dateFormat">{{ data.item.created_at | dateFormat}}</span>
                    </template>
                    <template v-slot:cell(location)="data">
                      {{ getLocation(data.item) }}
                    </template>
                    <template v-slot:cell(status)="data">
                      {{ getStatus(data.item.status) }}
                    </template>
                    <template v-slot:cell(progress_report)="data">
                      <a href="javascript:" class="btn_table_action table_action_view" v-if="data.item.progress_report_id !== null" v-b-modal.modal-2 :title="$t('irri_pump_main.progress_report')" @click="view(data.item, 2, $t('irri_pump_main.progress_report'))"><i class="fas fa-eye"></i></a>
                    </template>
                    <template v-slot:cell(action)="data">
                        <a href="javascript:" class="btn_table_action table_action_msg" v-b-modal.modal-1 :title="$t('irri_pump_main.resolved')" @click="view(data.item, 3, $t('irri_pump_main.resolved'))"><i class="fab fa-resolving"></i></a>
                        <a href="javascript:" class="btn_table_action table_action_status" v-if="data.item.complain_requisition_id === null" v-b-modal.modal-1 :title="$t('irri_pump_main.new_requisition')" @click="view(data.item, 4, $t('irri_pump_main.new_requisition'))"><i class="ri-add-circle-line"></i></a>
                        <a href="javascript:" class="btn_table_action table_action_view" v-if="data.item.complain_requisition_id !== null" v-b-modal.modal-1 :title="$t('irri_pump_main.view_requisition')" @click="view(data.item, 5, $t('irri_pump_main.view_requisition'))"><i class="far fa-eye"></i></a>
                        <a href="javascript:" class="btn_table_action table_action_edit" v-if="data.item.progress_report_id === null" v-b-modal.modal-1 :title="$t('irri_pump_main.add_progress_report')" @click="view(data.item, 6, $t('irri_pump_main.add_progress_report'))"><i class="ri-play-list-add-line"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="testClick"
                  />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
        <b-modal id="modal-1" size="xl" :title="modalTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <p>
            <!-- <IdentifiedEuipment :id="id" :complainId="complainId" :complainBy="complainBy" :key="id" v-if="modalType === 1"/> -->
            <!-- <ProgressReport :id="id" :complainId="complainId" :complainBy="complainBy" :key="id" v-if="modalType === 2"/> -->
            <Resolved :id="id" :complainId="complainId" :complainBy="complainBy" :key="id" v-if="modalType === 3" />
            <NewRequisition :id="id" :key="id" :org_id="org_id" v-if="modalType === 4" />
            <RequisitionDetails :id="id" :complain_requisition_id="complain_requisition_id" :key="id" v-if="modalType === 5"/>
            <AddProgressReport :id="id" :complainId="complainId" :complainBy="complainBy" :key="id" v-if="modalType === 6"/>
          </p>
        </b-modal>
        <b-modal id="modal-2" size="lg" :title="modalTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <b-button @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
            {{  $t('globalTrans.print') }}
          </b-button>
          <ProgressReport :id="id" :complainId="complainId" :complainBy="complainBy" :key="id" v-if="modalType === 2" ref="details"/>
        </b-modal>
        <b-modal id="modal-3" size="lg" :title="modalTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <b-button @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
            {{  $t('globalTrans.print') }}
          </b-button>
          <IdentifiedEuipment :id="id" :complainId="complainId" :complainBy="complainBy" :key="id" v-if="modalType === 1" ref="details"/>
          <!-- <RequisitionDetails :id="id" :complain_requisition_id="complain_requisition_id" :key="id" v-if="modalType === 5" ref="details"/> -->
          <!-- <RequisitionDetails :id="id" :complainId="complainId" :complainBy="complainBy" :key="id" v-if="modalType === 2" ref="details"/> -->
        </b-modal>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { maintenanceTaskList } from '../../api/routes'
import Resolved from './Resolved'
import IdentifiedEuipment from './IdentifiedEuipment'
import ProgressReport from './ProgressReport'
import AddProgressReport from './AddProgressReport'
import NewRequisition from './NewRequisition'
import RequisitionDetails from './RequisitionDetails'
import { mapGetters } from 'vuex'

export default {
  name: 'UiDataTable',
  components: {
    Resolved,
    IdentifiedEuipment,
    AddProgressReport,
    ProgressReport,
    NewRequisition,
    RequisitionDetails
  },
  data () {
    return {
      modalTitle: '',
      modalType: -111,
      farmer_id: '',
      search: {
        org_id: 0,
        complain_id: '',
        name: '',
        subject: ''
      },
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      id: 0,
      rows: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      irriUrl: irriSchemeServiceBaseUrl,
      complains: [],
      org_id: '',
      complainId: '',
      complainBy: '',
      complain_requisition_id: ''
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    formTitle () {
      return (this.testId === 0) ? this.$t('org_pro.office') + ' ' + this.$t('globalTrans.entry') : this.$t('org_pro.office') + ' ' + this.$t('globalTrans.modify')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
        { label: this.$t('irri_pump_main.complain_id'), class: 'text-center' },
        { label: this.$t('irri_pump_main.identified_quipment'), class: 'text-center' },
        { label: this.$t('irri_pump_main.location'), class: 'text-center' },
        { label: this.$t('globalTrans.status'), class: 'text-center' },
        { label: this.$t('irri_pump_main.progress_report'), class: 'text-center' },
        { label: this.$t('pump_install.created_date'), class: 'text-center' },
        { label: this.$t('irri_pump_main.action'), class: 'text-center w-15' }
      ]

      let keys = []

      keys = [
        { key: 'index' },
        { key: 'complain_id' },
        { key: 'identified_quipment' },
        { key: 'location' },
        { key: 'status' },
        { key: 'progress_report' },
        { key: 'updated_at' },
        { key: 'action' }
      ]
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  mounted () {
    core.index()
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id
      })
    }
    this.loadData()
  },
  methods: {
    resetId () {
      this.id = 0
    },
    testClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length
      }
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
      this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
    },
    view (item, modalType, modalTitle) {
      this.id = item.id
      this.modalTitle = modalTitle
      this.modalType = modalType
      this.org_id = item.org_id
      this.complainId = item.complain_id
      this.complain_requisition_id = item.complain_requisition_id
      this.farmer_id = item.farmer_id
      this.complainBy = (this.$i18n.locale === 'bn') ? item.name_bn : item.name
    },
    searchData () {
      if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.org_id
        })
      } else if (this.authUser.role_id === 0) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id
        })
      }
      this.loadData()
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      await RestApi.getData(irriSchemeServiceBaseUrl, maintenanceTaskList, params).then(response => {
        if (response.success) {
          this.complains = response.data.data
          this.paginationData(response.data)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getLocation (item) {
      const address = []
      const unionList = this.$store.state.commonObj.unionList
      const tmpUnion = unionList.find(union => union.value === item.far_union_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpUnion.text_bn !== undefined ? tmpUnion.text_bn : '')
      } else {
        address.push(tmpUnion.text_en !== undefined ? tmpUnion.text_en : '')
      }
      const upazilaList = this.$store.state.commonObj.upazilaList
      const tmpUpazila = upazilaList.find(upazila => upazila.value === item.far_upazilla_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpUpazila.text_bn !== undefined ? tmpUpazila.text_bn : '')
      } else {
        address.push(tmpUpazila.text_en !== undefined ? tmpUpazila.text_en : '')
      }
      const districtList = this.$store.state.commonObj.districtList
      const tmpDistrict = districtList.find(district => district.value === item.far_district_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpDistrict.text_bn !== undefined ? tmpDistrict.text_bn : '')
      } else {
        address.push(tmpDistrict.text_en !== undefined ? tmpDistrict.text_en : '')
      }
      const divisionList = this.$store.state.commonObj.divisionList
      const tmpDivision = divisionList.find(division => division.value === item.far_division_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpDivision.text_bn !== undefined ? tmpDivision.text_bn : '')
      } else {
        address.push(tmpDivision.text_en !== undefined ? tmpDivision.text_en : '')
      }
      return address.join(', ')
    },
    getStatus (status) {
      if (status === 1) {
        return 'Pending'
      } else if (status === 2) {
        return 'Received'
      } else if (status === 3) {
        return 'Approve'
      } else if (status === 4) {
        return 'Forward'
      } else if (status === 5) {
        return 'Allocate'
      } else if (status === 6) {
        return 'Quantity Edit'
      } else if (status === 7) {
        return 'Complete'
      } else if (status === 8) {
        return 'Resunk'
      }
    },
    pdfExport () {
      this.$refs.details.pdfExport()
    }
  }
}
</script>
