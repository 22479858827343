<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                        <b-col lg="12" sm="12">
                            <b-row>
                                <b-col lg="3" sm="12">
                                <p class="text-dark">{{ $t('irri_pump_main.complain_id') }} :</p>
                                </b-col>
                                <b-col lg="9" sm="12">
                                    <p class="text-dark">{{ pumpComplainId }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="3" sm="12">
                                <p class="text-dark">{{ $t('irri_pump_main.complained_by') }} :</p>
                                </b-col>
                                <b-col lg="9" sm="12">
                                    <p class="text-dark">{{ pumpComplainBy }}</p>
                                </b-col>
                            </b-row>
                            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                <b-form  @submit.prevent="handleSubmit(store)" @reset.prevent="reset" >
                                    <b-row>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider name="Progress Type" vid="progress_type" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="progress_type"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('irri_pump_main.progress_type')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="progress.progress_type"
                                                        :options="progressTypes"
                                                        id="progress_type"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider name="Progress Date" vid="progress_date" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="progress_date"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('irri_pump_main.progress_date')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        class="date-picker"
                                                        v-model="progress.progress_date"
                                                        placeholder="Select Date"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col lg="12" sm="12">
                                            <ValidationProvider name="Resolved Note (En)" vid="note" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="2"
                                                    label-for="note"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('irri_pump_main.notef')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-textarea
                                                        useCustomImageHandler
                                                        id="note"
                                                        v-model="progress.note"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-textarea>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col lg="12" sm="12">
                                            <ValidationProvider name="Resolved Note (Bn)" vid="note_bn">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="2"
                                                    :label="$t('irri_pump_main.note_bn')"
                                                    label-for="note_bn"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <b-form-textarea
                                                        id="note_bn"
                                                        v-model="progress.note_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-textarea>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <div class="row">
                                        <div class="col-sm-3"></div>
                                        <div class="col text-right">
                                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                            &nbsp;
                                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">{{ $t('globalTrans.cancel') }}</b-button>
                                        </div>
                                    </div>
                                </b-form>
                            </ValidationObserver>
                        </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { maintenanceProgressReportStore } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
    name: 'FormLayout',
    props: ['id', 'complainId', 'complainBy'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    mounted () {
        flatpickr('.date-picker', {})
        core.index()
    },
    data () {
        return {
            loading: false,
            saveBtnName: this.$t('globalTrans.save'),
            progressTypes: [
                { value: 1, text: 'Partial' },
                { value: 2, text: 'Not Done' },
                { value: 3, text: 'Complete' }
            ],
            progress: {
                complain_id: this.$props.id,
                progress_type: 0,
                note: '',
                note_bn: '',
                progress_date: ''
            },
            pumpComplainId: '',
            pumpComplainBy: ''
        }
    },
    created () {
        this.pumpComplainId = this.$props.complainId
        this.pumpComplainBy = this.$props.complainBy
    },
    methods: {
        async store () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadinState = { loading: false, listReload: false }
            result = await RestApi.postData(irriSchemeServiceBaseUrl, maintenanceProgressReportStore, this.progress)
            loadinState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadinState)
            this.loading = false
            if (result.success) {
                this.$store.dispatch('IrriConfig/mutateCommonObj', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: 'Success',
                    message: this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })
                this.$bvModal.hide('modal-1')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        }
    }
}
</script>
