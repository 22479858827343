<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <b-row>
                      <b-col lg="2" sm="12">
                        <p class="text-dark">{{ $t('irri_pump_main.complain_id') }} :</p>
                      </b-col>
                      <b-col lg="10" sm="12">
                          <p class="text-dark">{{ pumpComplainId }}</p>
                      </b-col>
                  </b-row>
                  <b-row>
                      <b-col lg="2" sm="12">
                        <p class="text-dark">{{ $t('irri_pump_main.complained_by') }} :</p>
                      </b-col>
                      <b-col lg="10" sm="12">
                          <p class="text-dark">{{ pumpComplainBy }}</p>
                      </b-col>
                  </b-row>
                  <b-row>
                      <b-col lg="2" sm="12">
                        <p class="text-dark">{{ $t('irri_pump_main.progress_type') }} :</p>
                      </b-col>
                      <b-col lg="10" sm="12">
                          <p class="text-dark">{{ getProgressType(progressReport.progress_type) }}</p>
                      </b-col>
                  </b-row>
                  <b-row>
                      <b-col lg="2" sm="12">
                        <p class="text-dark">{{ $t('irri_pump_main.note') }} :</p>
                      </b-col>
                      <b-col lg="10" sm="12">
                          <p class="text-dark">{{ ($i18n.locale === 'bn') ? progressReport.note_bn : progressReport.note }}</p>
                      </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { maintenanceProgressReport } from '../../api/routes'
import ExportPdf from './PDFProgress'
export default {
    name: 'FormLayout',
    props: ['id', 'complainId', 'complainBy'],
    mounted () {
        core.index()
    },
    data () {
        return {
          loading: false,
          saveBtnName: this.$t('globalTrans.save'),
          pumpComplainId: '',
          pumpComplainBy: '',
          progressReport: '',
          progessType: ''
        }
    },
    created () {
        this.pumpComplainId = this.$props.complainId
        this.pumpComplainBy = this.$props.complainBy
        this.getProgressReport()
    },
    methods: {
        async getProgressReport () {
          this.loading = true
          let result = null
          result = await RestApi.getData(irriSchemeServiceBaseUrl, maintenanceProgressReport + '/' + this.$props.id)
          if (result.success) {
            this.progressReport = result.data
          }
          this.loading = false
        },
        getProgressType (type) {
          if (type === 1) {
            this.progessType = 'Partial'
            return 'Partial'
          } else if (type === 2) {
            this.progessType = 'Not Done'
            return 'Not Done'
          } else {
            this.progessType = 'Complete'
            return 'Complete'
          }
        },
        pdfExport () {
          const reportTitle = this.$i18n.locale === 'en' ? 'Maintenance Task (Progress Report)' : 'কার্য রক্ষণাবেক্ষণ (অগ্রগতি প্রতিবেদন)'
          ExportPdf.exportPdfDetails(irriSchemeServiceBaseUrl, '/report-heading/detail', 3, reportTitle, this.progressReport, this.progessType, this.pumpComplainBy, this)
        }
    }
}
</script>
